import React from "react";
import "./produk.scss";
import prdk from "./produk.json";
import Cardrumah from "../../component/cardrumah/cardrumah";
import rumah from "../../asset/produk/rumah";
import ruko from "../../asset/produk/ruko";
import Cardruko from "../../component/cardruko/cardruko";

const Produk = () => {
  return (
    <div className="produk">
      <div className="container-produk">
        <div className="header-produk">Residential's Product</div>
        <div className="cardhome-container">
          {prdk.cards.map((card, index) => {
            // Determine the logo dynamically
            const lavia = rumah[card.imagehs];
            return (
              <Cardrumah
                key={index}
                imagehs={lavia}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
        <div className="header-produkr">Commercial's Product</div>
        <div className="cardhome-container">
          {prdk.cardsr.map((card, index) => {
            // Determine the logo dynamically
            const lavia = ruko[card.imagehs];
            return (
              <Cardruko
                key={index}
                imagehs={lavia}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Produk;
