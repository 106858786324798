import React from "react";
import "./cardrumah.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faHouse,
  faMaximize,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const cardrumah = ({ imagehs, title, subtitle, details, whatsappLink }) => {
  return (
    <div className="cardrumahdetail">
      <div className="cluster-card">
        <div className="container-gambar">
          <img className="gambar" src={imagehs} alt="tampak-depan" />
        </div>
        <div className="cluster-card-title">
          <div className="cluster-name">
            <div className="cluster-name-sub">{title}</div>
            <div className="cluster-name-sub2">{subtitle}</div>
          </div>
        </div>
        <div className="gridspek">
          <FontAwesomeIcon icon={faMaximize} />
          <span>&nbsp;: {details.area}&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faHouse} />
          <span>&nbsp;: {details.buildingSize}&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faBed} />
          <span>&nbsp;: {details.bedrooms}&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={faShower} />
          <span>&nbsp;: {details.bathrooms}&nbsp;&nbsp;</span>
        </div>
        <div className="cluster-button">
          <button
            onClick={() => window.open(whatsappLink, "_blank")}
            className="cluster-whatsapp"
          >
            <FontAwesomeIcon size="lg" icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default cardrumah;
