import React from "react";
import "./surrounding.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import surrounding from "../../asset/surrounding/surrounding.js";

const Surrounding = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        lazyload: true, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile (optional)
          swipeToSlide: true,
          lazyload: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div id="surrounding" className="surrounding">
      <div className="container-surrounding">
        <div className="header">SURROUNDING AREA</div>
        <div className="carousel-lokasi">
          <Slider {...settings}>
            {surrounding.map((image, index) => (
              <div>
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt="surrounding"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Surrounding;
