import React from "react";
import "./promo.scss";
import Promoimage from "./promo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { GiLift } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { TbAirConditioning, TbSmartHome } from "react-icons/tb";
import { GiShower } from "react-icons/gi";
import { AiOutlineSafety } from "react-icons/ai";

const Promo = () => {
  const wapromo = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=%206289506924184&text=Halo+Adi,%20Saya%C2%A0bisa%20minta%20Brosur,%20Simulasi,%20Promo%20terbaru%20rumah%20ini%20%20https://marketing-citragardencity.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="promo" className="container-promo">
      <div className=" container-listpromo">
        <div className="containercontent">
          <div className="judul-promo">Promo</div>
          <div className="p">
            <div className="pointpenawaran">
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span>&nbsp;&nbsp;Soft DP</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span>&nbsp;&nbsp;Free BPHTB </span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span>&nbsp;&nbsp;Free Biaya BBN</span>
              </div>
            </div>
            <div className="pointpenawaran">
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span>&nbsp;&nbsp;Free AJB</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span>&nbsp;&nbsp;Free Smartdoor Lock</span>
              </div>
              <div className="penawaranpoin">
                <FontAwesomeIcon icon={faMedal} />
                <span>&nbsp;&nbsp;Free AC </span>
              </div>
            </div>
          </div>
          <div className="list-promo">
            <div className="list1">
              <ul className="a">
                <li>
                  <GiLift size="70px" />
                  &nbsp;&nbsp;
                  <span>Free Lift</span>
                </li>
                <li>
                  <FaKitchenSet size="70px" />
                  &nbsp;&nbsp;
                  <span>Free Kitchen Set</span>
                </li>
                <li>
                  <TbAirConditioning size="70px" />
                  &nbsp;&nbsp;
                  <span>Free AC</span>
                </li>
              </ul>
            </div>
            <div className="list1">
              <ul className="a">
                <li>
                  <TbSmartHome size="70px" />
                  &nbsp;&nbsp;
                  <span>Free Smarthome System</span>
                </li>
                <li>
                  <GiShower size="70px" />
                  &nbsp;&nbsp;
                  <span>Solar Water Heater</span>
                </li>
                <li>
                  <AiOutlineSafety size="70px" />
                  &nbsp;&nbsp;
                  <span>3 Years Waranty</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <div className="button-wa-promo">
            <button onClick={wapromo} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Get More Promo
            </button>
          </div>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={Promoimage} alt="hardsell" />
        </div>
      </div>
    </div>
  );
};

export default Promo;
