import React from "react";
import "./about.scss";
import { PiHouse, PiPark, PiRoadHorizon } from "react-icons/pi";

const about = () => {
  return (
    <div id="about" className="about">
      <div className="container-about">
        <div className="header-about">
          <div className="judul-about">Citra Garden City Jakarta</div>
          <div className="desk-about">
            Citra Garden City adalah sebuah proyek perumahan berskala besar yang
            mulai dikembangkan pada tahun 1984 oleh Cipura Residence. Proyek ini
            memiliki luas sekitar 450 hektar dan telah membangun lebih dari
            10.000 unit rumah dengan populasi yang mencapai 50.000 jiwa. itra
            Garden City adalah kompleks perumahan terintegrasi yang dilengkapi
            dengan berbagai fasilitas, termasuk pengolahan air bersih, sport
            club, fasilitas pendidikan, klinik, dan pasar. 
          </div>
        </div>
        <div className="list-benefit">
          <div className="list">
            <ul>
              <li>
                <PiHouse size="70px" color="white" />
                &nbsp;&nbsp;
                <span>Premium Cluster</span>
              </li>
              <li>
                <PiRoadHorizon size="70px" color="white" />
                &nbsp;&nbsp;
                <span>Easy Access</span>
              </li>
              <li>
                <PiPark size="70px" color="white" />
                &nbsp;&nbsp;
                <span>Private Clubhouse</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
