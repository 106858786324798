import Surrounding1 from "./surrounding (1).webp";
import Surrounding2 from "./surrounding (2).webp";
import Surrounding3 from "./surrounding (3).webp";
import Surrounding4 from "./surrounding (4).webp";
import Surrounding5 from "./surrounding (5).webp";
import Surrounding6 from "./surrounding (6).webp";

const surrounding = [
  Surrounding1,
  Surrounding2,
  Surrounding3,
  Surrounding4,
  Surrounding5,
  Surrounding6,
];

export default surrounding;
