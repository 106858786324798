import React from "react";
import "./lokasi.scss";
import mapsimage from "./dekstop-maps.jpeg";
import mobilemaps from "./mobile-maps.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  return (
    <div className="lokasi">
      <div className="judul">Lokasi</div>
      <div className="maps-lokasi">
        <img
          id="lokasi"
          className="img-lokasi-dekstop"
          src={mapsimage}
          alt="maps"
        />
        <img
          id="lokasi"
          className="img-lokasi-mobile"
          src={mobilemaps}
          alt="maps"
        />
      </div>
      <div className="benefit-container1">
        <div className="benefit-point2">
          <div className="benefit-title3">
            <FontAwesomeIcon icon={faGraduationCap} /> &nbsp;Education
          </div>
          <div className="benefit4">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;4 Menit ke Pelita Bangsa International School
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;6 Menit ke SMA Negeri 84 Jakarta
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;15 Menit ke Mercu Buana University
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;20 Menit ke Binus University
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;20 Menit ke Podomoro Unviversity
            </div>
          </div>
        </div>
        <div className="benefit-point2">
          <div className="benefit-title3">
            <FontAwesomeIcon icon={faHouse} /> &nbsp;Entertiment
          </div>
          <div className="benefit4">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;1 Menit ke McDonald's
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;1 Menit ke Starbucks
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;1 Menit KFC
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;1 Menit ke Magurame Udon
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;15 Menit ke Puri Indah Mall
            </div>
          </div>
        </div>
        <div className="benefit-point2">
          <div className="benefit-title3">
            <FontAwesomeIcon icon={faBuilding} /> &nbsp;Health Care
          </div>
          <div className="benefit4">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;3 Menit ke Ciputra Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;10 Menit ke RS Bedah Cinta Kasih Tzu Chi
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;10 Menit ke RS Mitra Keluarga
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;20 Menit ke Rumah Sakit Harapan Kita
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;20 Menit ke Rumah Skit Dharmais
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
