import React from "react";
import "./navbar.scss";
import logo from "../../asset/logo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const navbar = () => {
  const wanavbar = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=%206289506924184&text=Halo+Adi,%20Saya%C2%A0bisa%20minta%20Brosur,%20Simulasi,%20Promo%20terbaru%20rumah%20ini%20%20https://marketing-citragardencity.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="navbar">
      <div className="container-navbar">
        <div className="logo-navbar">
          <img src={logo} alt="logo-cgs" />
        </div>
        <div className="buttonwa">
          <button onClick={wanavbar} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;&nbsp; Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default navbar;
