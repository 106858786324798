import React from "react";
import "./newlaunch.scss";
import Cardrumah from "../../component/cardrumah/cardrumah";
import highlight from "../../asset/highlight/highlight";
import prm from "./newlaunch.json";
import Laviaimages from "../../asset/lavia/lavia";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Newlaunch = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        lazyload: true, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile (optional)
          swipeToSlide: true,
          lazyload: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div id="newlaunch" className="container-newlaunch">
      <div className="dividera"></div>
      <div className=" container-listnewlaunch">
        <div className="header-nwlnewlaunch">
          <div className="judul-newlaunch">New Launching </div>
          <div className="subjudul-newlaunch">Lavia</div>
        </div>
        <div className="carousel-lavia">
          <Slider {...settings}>
            {highlight.map((image, index) => (
              <div>
                <img
                  className="kartugambar"
                  key={index}
                  src={image}
                  alt="highlight"
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="cardhome-container">
          {prm.cards.map((card, index) => {
            // Determine the logo dynamically
            const lavia = Laviaimages[card.imagehs];
            return (
              <Cardrumah
                key={index}
                imagehs={lavia}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
      <div className="dividerb" />
    </div>
  );
};

export default Newlaunch;
