import React from "react";
import "./footer.scss";
import logo from "../../asset/logo.webp";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-bsd" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h2>Marketing Gallery</h2>
            <h1>Citra Garden City Jakarta</h1>
            <p>
              Jl. Citra Garden City 6, Kota Jakarta Barat, Daerah Khusus Ibukota
              Jakarta 11820
            </p>
          </div>
          <div className="contact">Contact Marketing : +6289506924184 </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">CITRA GARDEN CITY JAKARTA</div>
    </div>
  );
};

export default footer;
